// import dependencies
import React from "react";
import {graphql, Link} from "gatsby";
import Image from "gatsby-plugin-sanity-image";

// import components
import BigCard from "../components/big-card";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import Resource from "../components/resource";
import Section from "../components/section";
import SectionHeader from "../components/section-header";
import SeparatorTopGrey from "../components/separators/separator-top-grey";
import SeparatorTopYellow from "../components/separators/separator-top-yellow";

// import styles and assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';
import corfoImg from "../images/stickers-cuadrados-kit.jpg";
import * as styles from "./styles/cajas-stem.module.scss";

export default function StemCorfo({data, pageContext}) {
    return (
<Layout>
    <div className={styles.intro}></div>
    <Section color={'white'}>
        <BigCard color="white">
            <div className={`d-flex flex-column col-sm-12 order-sm-last col-md-6 order-md-first align-self-center ${styles.introText}`}> 
                <h1>Kit Ingeniosamente Corfo</h1>
                <h4>El objetivo de la elaboración del Kit Ingeniosamente es inspirar a las niñas en ciencias y tecnología y que puedan desarrollar los diferentes talleres de Ingeniosas con materiales reales y así se motiven a seguir el camino STEM. Están compuestas por diferentes materiales, tanto como para hacer talleres de electrónica, como de ciencia.</h4>
                <h4>Materiales electrónica</h4>
                <ul>
                    <li><span><FontAwesomeIcon icon={["fas", "circle"]} /></span> Protoboard</li>
                    <li><span><FontAwesomeIcon icon={["fas", "circle"]} /></span> Jumpers</li>
                    <li><span><FontAwesomeIcon icon={["fas", "circle"]} /></span> Luces led</li>
                    <li><span><FontAwesomeIcon icon={["fas", "circle"]} /></span> Resistencia</li>
                    <li><span><FontAwesomeIcon icon={["fas", "circle"]} /></span> Batería o pilas</li>
                    <li><span><FontAwesomeIcon icon={["fas", "circle"]} /></span> Botón pulsador</li>
                </ul>
                <h4>Materiales ciencias</h4>
                <ul>
                    <li><span><FontAwesomeIcon icon={["fas", "circle"]} /></span> Vasos</li>
                    <li><span><FontAwesomeIcon icon={["fas", "circle"]} /></span> Plumón o marcador negro</li>
                    <li><span><FontAwesomeIcon icon={["fas", "circle"]} /></span> Plumón o marcador (o tempera) amarilla</li>
                </ul>
                <h6>Con estos materiales podrán realizar los talleres que están a continuación:</h6>
            </div>
            <div className="col-sm-12 order-sm-first col-md-6 order-md-last align-self-center">
                <img src={corfoImg} alt="kits interactivos"/>
            </div>
        </BigCard>
    </Section>
    <SeparatorTopYellow></SeparatorTopYellow>
    <Section color={'yellow'}>
        <div className="container">
            <div className={`row ${styles.titleRow}`}>
                <div className="col-12">
                    <SectionHeader>
                        <h1>Videos</h1>
                        <h4>Experimenta con la electrónica y la ciencia en estos talleres para realizarlos con materiales reales.</h4>
                    </SectionHeader>
                </div>
            </div>
            <div className={`row ${styles.videoRow}`}>
                {data.allSanityPost.nodes.map((node) => (  
                    <div key={node.id} className="col-sm-12 col-md-6">
                        <Resource>
                            <div className="photo" key={node.mainImage.asset.id}>
                                <Image 
                                    {...node.mainImage}
                                    width={320}
                                    height={320}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                    alt="Imágen de taller Corfo" />
                            </div>
                            <div className="box">
                                <h4>{node.title}</h4>
                                <div className="separator"></div>
                                <p>{node.excerpt}</p>
                                <Link to={`/caja-stem-corfo/${node.slug.current}`}><FontAwesomeIcon icon={["fas", "arrow-right"]} /></Link>
                            </div>
                        </Resource>
                        {/* <VideoPost key={node.id} videoLink={node.video[0].url}>
                            <h4>{node.title}</h4>
                            <p>{node.description}</p>
                        </VideoPost> */}
                    </div>
                ))}
            </div>
            <div className="row">
                <div className="col-12">
                    <Pagination pageContext={pageContext}></Pagination>
                </div>
            </div>
        </div>
        <SeparatorTopGrey />
    </Section>
</Layout>
    );
}

export const query = graphql`
query cajaStemCorfoPageQuery ($skip: Int!, $limit: Int!) {
    allSanityPost(
    filter: {category: {title: {in: ["Cajas STEM", "Actividades para niñas CORFO"]}}, 
    tags: {elemMatch: {title: {in: "Corfo"}}}},
    sort: {order: DESC, fields: publishedAt},
    skip: $skip, 
    limit: $limit) {
        nodes {
            id
            title
            slug {
                current
            }
            excerpt
            mainImage {
                ...ImageWithPreview
            }
        }
    }
  }
`;